import React from 'react'
import { useLocation } from '@gatsbyjs/reach-router'
import { Link, withPrefix } from 'gatsby'
import cn from 'classnames'
import handleScroll from '~utils/handleScroll'
import logo from './assets/logo.svg'

import * as s from './Logo.module.scss'

const Logo = ({ alt, ...rest }) => {
  const location = useLocation()
  const isHomepage = location.pathname === withPrefix('/')

  const linkProps = isHomepage
    ? {
        href: '/',
        onClick: (e) => handleScroll(e, 0),
      }
    : {
        to: '/',
      }

  return React.createElement(
    isHomepage ? 'a' : Link,
    {
      className: cn(s.logo, { [s.isLink]: !isHomepage }),
      ...linkProps,
      ...rest,
    },
    <img src={logo} width={47} height={28} alt={alt} className={s.logo_pic} />
  )
}

export default Logo
