const MENU = [
  {
    name: 'About us',
    link: '#about-us',
  },
  {
    name: 'Services',
    link: '#services',
  },
  {
    name: 'Team',
    link: '#team',
  },
  {
    name: 'Our work',
    link: '#our-work',
  },
]

export default MENU
