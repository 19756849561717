import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

const handleScroll = (e, link) => {
  e.preventDefault()
  gsap.to(window, { scrollTo: link, ease: 'power2' })
}

export default handleScroll
