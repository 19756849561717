import { useState } from 'react'
import throttle from 'lodash/throttle'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])
  useIsomorphicLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', throttle(updateSize, 200))
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export default useWindowSize
