import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { Button, Container, Offcanvas } from 'react-bootstrap'
import PropTypes from 'prop-types'

import handleScroll from '~utils/handleScroll'
import { CONTACT_US_LINK } from '~constants'
import useMatchMedia from '~hooks/useMatchMedia'
import useIsomorphicLayoutEffect from '~hooks/useIsomorphicLayoutEffect'
import { useLocation } from '@gatsbyjs/reach-router'
import { withPrefix } from 'gatsby'
import useBelowHero from '~hooks/useBelowHero'
import appearAnim from './anim'
import Menu from '../Menu'
import BtnBurger from '../BtnBurger'
import Logo from './Logo'
import * as s from './Header.module.scss'

const Header = ({ siteTitle }) => {
  const [show, setShow] = useState(false)
  const isMobile = useMatchMedia('(max-width: 767.98px)')
  const toggleOffcanvas = () => setShow((prev) => !prev)
  const handleClose = () => setShow(false)
  const headerRef = useRef(null)

  const handleClick = (e, link) => {
    handleScroll(e, link)
    handleClose()
  }

  const location = useLocation()
  const isHomepage = location.pathname === withPrefix('/')

  useIsomorphicLayoutEffect(() => {
    appearAnim({ ref: headerRef.current, delay: isHomepage ? 0.6 : 0.4 })
  }, [])

  const isBelowHero = useBelowHero()

  return (
    <header className={s.header} ref={headerRef}>
      <Container>
        <div className={cn(s.header_inner, { [s.show]: show })}>
          <Logo alt={siteTitle} />

          <Offcanvas
            placement="top"
            responsive="md"
            show={show}
            onHide={handleClose}
            scroll
          >
            <Offcanvas.Body>
              <Menu onSelect={handleClose} variant="header">
                <div className={s.header_menuBtn}>
                  <Button
                    href={CONTACT_US_LINK}
                    onClick={(e) => handleClick(e, CONTACT_US_LINK)}
                    variant={
                      isBelowHero || isMobile ? 'secondary' : 'outline-dark'
                    }
                    size="sm"
                  >
                    Contact us
                  </Button>
                </div>
              </Menu>
            </Offcanvas.Body>
          </Offcanvas>

          <BtnBurger
            className={s.header_hamb}
            isMenuOpen={show}
            onClick={toggleOffcanvas}
          />
        </div>
      </Container>
    </header>
  )
}

Header.defaultProps = {
  siteTitle: '',
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
