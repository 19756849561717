import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { Button, Form as F, Spinner } from 'react-bootstrap'
import { useSubmit } from '@formspree/react'
import trim from 'lodash/trim'
import { FORM_DATA, FORM_ERRORS } from './constants'

import * as s from './Form.module.scss'

const RESET_TIME = 10

const Form = ({ className, scaleIn, scaleOut }) => {
  const formRef = useRef(null)
  const [validated, setValidated] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [counter, setCounter] = useState(0)
  const [validationErrors, setValidationErrors] = useState({})
  const [submittingError, setSubmittingError] = useState({})

  const handleShowSubmittingError = (message) =>
    setSubmittingError({ show: true, message })

  const handleError = (input) => {
    const {
      name,
      value,
      validity: { valueMissing, typeMismatch, patternMismatch, tooShort },
    } = input
    const isInvalidValue = typeMismatch || patternMismatch
    const trimmedVal = trim(value)

    const getErrorVariant = () => {
      if (trimmedVal && tooShort) return 'short'
      if (isInvalidValue || (value && !trimmedVal)) return 'invalid'
      return 'required'
    }

    setValidationErrors((prev) => ({
      ...prev,
      [name]: {
        show: valueMissing || isInvalidValue || tooShort,
        text: FORM_ERRORS[getErrorVariant()][name],
      },
    }))
  }

  const handleReset = () => {
    const f = formRef.current

    setValidated(false)
    setSubmittingError((prev) => ({ ...prev, show: false }))
    f.reset()

    scaleOut()
  }

  const handleSend = useSubmit(process.env.GATSBY_FORMSPREE_ID || 'xyyadbpa', {
    onError(error) {
      setSubmitting(false)
      const formErrors = error.getFormErrors()
      handleShowSubmittingError(formErrors[0]?.message)
    },
    onSuccess() {
      setSubmitting(false)
      scaleIn(1.3)
      setCounter(RESET_TIME)

      const interval = setInterval(() => {
        setCounter((prev) => {
          if (!prev) {
            clearInterval(interval)
            return prev
          }
          return prev - 1
        })
      }, 1000)
      setTimeout(handleReset, (RESET_TIME - 0.5) * 1000)
    },
  })

  const onSubmit = (event) => {
    event.preventDefault()
    setValidated(true)

    const f = event.target
    if (f.checkValidity()) {
      setSubmitting(true)
      handleSend(event)
    }
  }

  const onChange = (e) => {
    const input = e.currentTarget

    if (validated && input) {
      handleError(input)
    }
  }

  const onInvalid = (e) => {
    const input = e.target

    if (input) {
      handleError(input)
    }
  }

  const isDisabled = submitting || counter || submittingError?.show

  return (
    <div className={s.form}>
      <F
        onSubmit={onSubmit}
        validated={validated}
        noValidate
        autoComplete="off"
        ref={formRef}
        className={cn(
          'form',
          s.form_inner,
          {
            [s.fadein]: !submittingError?.show && !counter,
            [s.fadeout]: counter || submittingError?.show,
          },
          className
        )}
      >
        {FORM_DATA.map((item) => {
          const { name, type } = item

          const isTextarea = type === 'textarea'
          const itemProps = isTextarea ? { className: 'form-textarea' } : {}

          return (
            <F.Group
              key={name}
              className={cn(s.form_item, 'form-group', {
                [`form-${name}`]: name === 'description',
              })}
            >
              {React.createElement(
                isTextarea ? 'div' : React.Fragment,
                itemProps,
                <F.Control
                  {...item}
                  onChange={onChange}
                  onInvalid={onInvalid}
                  disabled={isDisabled}
                />
              )}

              <span
                className={cn('form-errorMsg', {
                  show: validationErrors[name]?.show,
                })}
              >
                {validationErrors[name]?.text || ''}
              </span>
            </F.Group>
          )
        })}

        <Button
          type="submit"
          variant="secondary"
          disabled={isDisabled}
          className={cn('form-btn', s.form_item, s.form_btn)}
          onMouseEnter={() => scaleIn(1.1)}
          onMouseLeave={scaleOut}
        >
          {isDisabled ? <Spinner /> : 'Submit'}
        </Button>
      </F>

      <div
        className={cn(s.feedback, s.feedback_succeed, {
          [s.fadein]: counter,
          [s.fadeout]: counter === 1,
        })}
      >
        <span className={cn(s.feedback_message, s.feedback_animated)}>
          The email was sent successfully, we will contact you soon!
        </span>
        <span
          className={cn(
            s.feedback_countdown,
            s.feedback_text,
            s.feedback_animated
          )}
        >
          The form will return in {counter || 1} seconds
        </span>
      </div>

      <div
        className={cn(s.feedback, s.feedback_error, {
          [s.fadein]: submittingError.show,
          [s.fadeout]: !submittingError.show && submittingError.message,
        })}
      >
        <span className={cn(s.feedback_message, s.feedback_animated)}>
          Something went wrong
        </span>
        <span
          className={cn(s.feedback_descr, s.feedback_text, s.feedback_animated)}
        >
          {submittingError.message || ''}
        </span>
        <Button
          variant="secondary"
          onClick={handleReset}
          disabled={!submittingError.show}
          className={cn(s.feedback_btn, s.feedback_animated)}
        >
          Try again
        </Button>
      </div>
    </div>
  )
}

export default Form
