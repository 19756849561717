import React from 'react'
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Icon from '~components/Icon'
import * as s from './Footer.module.scss'

const Footer = ({ siteTitle }) => {
  const currentYear = new Date().getFullYear()

  return (
    <Container as="footer" className={s.footer}>
      <div>
        © {currentYear} {siteTitle}. All rights reserved
      </div>
      <a
        href="https://twitter.com/h3atstudios"
        target="_blank"
        rel="noreferrer"
        aria-label={`${siteTitle} Twitter`}
        className={s.footer_social}
      >
        <Icon name="icon-social-twitter" />
      </a>
      <div className={s.footer_company}>
        Designed & developed by{' '}
        <a
          href="https://min.studio/"
          target="_blank"
          rel="noreferrer"
          className={s.footer_link}
        >
          Minimal
        </a>
      </div>
    </Container>
  )
}

Footer.defaultProps = {
  siteTitle: '',
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

export default Footer
