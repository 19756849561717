/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { Graphics, Stage, Container } from '@pixi/react'
import { CIRCLES_DATA_DEFAULT } from '~constants'

import * as PIXI from 'pixi.js'
import { gsap } from 'gsap'
import { PixiPlugin } from 'gsap/PixiPlugin'
import useWindowSize from '~hooks/useWindowSize'
import drawCircle from './utils'

// register the plugin
gsap.registerPlugin(PixiPlugin)

// give the plugin a reference to the PIXI object
PixiPlugin.registerPIXI(PIXI)

const CircleBg = React.forwardRef((props, canvasRef) => {
  const {
    className,
    width,
    height,
    isGray,
    isColor,
    circlesGrey,
    circlesColor,
    variant,
  } = props
  const [maskContainerRef, setMaskContainerRef] = useState(null)
  const [windowWidth, windowHeightRaw] = useWindowSize()
  const windowHeight = windowHeightRaw * 1.5

  const canvasWidth = width ?? windowWidth
  const canvasHeight = height ?? windowHeight

  const halfWidth = canvasWidth / 2
  const halfHeight = canvasHeight / 2

  const isHero = variant === 'hero'
  const isWork = variant === 'work'

  const xPosition = isHero ? halfWidth : canvasWidth
  const yPosition = isWork ? canvasHeight * 1.2 : halfHeight

  useEffect(() => {
    if (isHero && maskContainerRef) {
      const mask = new PIXI.Graphics()
      mask.pivot = { x: halfWidth, y: halfHeight }
      mask.beginFill(0xffffff)
      mask.drawRoundedRect(0, halfHeight, canvasWidth, halfHeight, 40)
      mask.endFill()

      maskContainerRef.mask = mask
      maskContainerRef.addChild(mask)

      return () => {
        maskContainerRef.mask = null
        maskContainerRef.removeChild(mask)
      }
    }

    return undefined
  }, [canvasWidth, maskContainerRef, canvasHeight])

  return (
    <Stage
      ref={(el) => {
        if (canvasRef) {
          // eslint-disable-next-line no-underscore-dangle
          canvasRef.current = el?._canvas
        }
      }}
      className={className}
      width={canvasWidth}
      height={canvasHeight}
      options={{
        backgroundAlpha: 0,
      }}
    >
      {isGray && (
        <Container position={[halfWidth, yPosition]}>
          {CIRCLES_DATA_DEFAULT.map(({ radius, grey }, i) => (
            <Graphics
              key={grey}
              pivot={[halfWidth, halfHeight]}
              draw={(g) =>
                drawCircle({
                  g,
                  radius,
                  color: grey,
                  x: halfWidth,
                  y: halfHeight,
                  canvasWidth,
                  isHero,
                })
              }
              ref={(el) => {
                if (circlesGrey) circlesGrey[i] = el
              }}
            />
          ))}
        </Container>
      )}

      {isColor && (
        <Container ref={setMaskContainerRef} position={[xPosition, halfHeight]}>
          {CIRCLES_DATA_DEFAULT.map(({ radius, color }, i) => (
            <Graphics
              key={color}
              pivot={[xPosition, halfHeight]}
              draw={(g) =>
                drawCircle({
                  g,
                  radius,
                  color,
                  x: xPosition,
                  y: halfHeight,
                  canvasWidth,
                  isHero,
                })
              }
              ref={(el) => {
                if (circlesColor) circlesColor[i] = el
              }}
            />
          ))}
        </Container>
      )}
    </Stage>
  )
})

export default CircleBg
