import React from 'react'
import cn from 'classnames'
import { useLocation } from '@gatsbyjs/reach-router'
import { withPrefix } from 'gatsby'
import { Button } from 'react-bootstrap'
import useBelowHero from '~hooks/useBelowHero'
import Icon from '~components/Icon'
import * as s from './BtnCta.module.scss'

const BtnCta = React.forwardRef(
  ({ onMouseOver, onMouseLeave, isFloating, variant, className }, ref) => {
    const location = useLocation()
    const isHomepage = location.pathname === withPrefix('/')

    const showButton = useBelowHero()

    return (
      <Button
        ref={ref}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        variant={variant}
        href="https://calendly.com/h3atmedia/30min"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Go to calendly"
        className={cn(
          s.btn,
          { [s.float]: isFloating },
          { [s.show]: isFloating && (!isHomepage || showButton) },
          className
        )}
      >
        <Icon name="icon-phone" size={32} />
      </Button>
    )
  }
)

export default BtnCta
