import { gsap } from 'gsap'

const appearAnim = ({ ref, delay }) => {
  gsap.fromTo(
    ref,
    { yPercent: -100, autoAlpha: 0 },
    { yPercent: 0, autoAlpha: 1, duration: 1, delay }
  )
}

export default appearAnim
