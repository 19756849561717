const drawCircle = ({ g, radius, color, x, y, canvasWidth, isHero }) => {
  const radiusModifier = !isHero || canvasWidth > 1199 ? canvasWidth : 1440

  g.clear()
  g.beginFill(color)
  g.drawCircle(x, y, radius * radiusModifier * (isHero ? 1 : 2))
  g.endFill()
}

export default drawCircle
