import React from 'react'
import cn from 'classnames'

import { Dropdown } from 'react-bootstrap'
import * as s from './BtnBurger.module.scss'
import BTN_BURGER_LABEL from './constants'

const BtnBurger = ({ isMenuOpen, onClick, className }) => (
  <Dropdown.Toggle
    as="button"
    className={cn(s.hamb, className)}
    onClick={onClick}
    aria-expanded={isMenuOpen}
    aria-label={BTN_BURGER_LABEL[+isMenuOpen]}
    title={BTN_BURGER_LABEL[+isMenuOpen]}
  >
    <svg fill="none" viewBox="0 0 24 24" className={s.hamb_icon}>
      <path
        className={cn(s.hamb_line, s.hamb_line_top)}
        d="M4 8h16 c1 0 3 .4 3 2s-2 2-3 2 h-8"
      />
      <path className={cn(s.hamb_line, s.hamb_line_middle)} d="M4 12 h16" />
      <path
        className={cn(s.hamb_line, s.hamb_line_bottom)}
        d="M20 16 h-16 c-1 0-3 -.4-3 -2s2 -2 3 -2h8"
      />
    </svg>
  </Dropdown.Toggle>
)

export default BtnBurger
