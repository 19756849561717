import React from 'react'
import { useLocation } from '@gatsbyjs/reach-router'
import PropTypes from 'prop-types'
import { Nav } from 'react-bootstrap'
import cn from 'classnames'

import handleScroll from '~utils/handleScroll'
import { Link, withPrefix } from 'gatsby'
import MENU from './constants'

const MenuItem = ({ link, name, onSelect }) => {
  const location = useLocation()
  const isHomepage = location.pathname === withPrefix('/')

  const linkProps = isHomepage
    ? {
        as: 'a',
        href: '/',
        onClick: (e) => handleScroll(e, link),
      }
    : {
        as: Link,
        to: `/${link}`,
        onClick: () => onSelect(),
      }

  return <Nav.Link {...linkProps}>{name}</Nav.Link>
}

const Menu = ({ children, onSelect, variant }) => {
  return (
    <Nav
      onSelect={onSelect}
      className={cn({ [`nav--${variant}`]: variant })}
      as="ul"
    >
      {MENU.map((el) => (
        <Nav.Item as="li" key={el.name}>
          <MenuItem {...el} onSelect={onSelect} />
        </Nav.Item>
      ))}
      {children}
    </Nav>
  )
}

Menu.defaultProps = {
  variant: '',
}

Menu.propTypes = {
  variant: PropTypes.string,
}

export default Menu
