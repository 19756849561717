import React, { useRef } from 'react'
import { Container } from 'react-bootstrap'
import CircleBg from '~components/CircleBg'
import Form from '~components/Form'

import useIsomorphicLayoutEffect from '~hooks/useIsomorphicLayoutEffect'
import { useLocation } from '@gatsbyjs/reach-router'
import { canvasAppearAnimCta, scaleIn, scaleOut } from './canvasAnim'
import * as s from './CTA.module.scss'

const CTA = () => {
  const { pathname } = useLocation()
  const canvasRef = useRef(null)
  const circlesRef = useRef([])

  useIsomorphicLayoutEffect(() => {
    let tl

    const timeout = setTimeout(() => {
      if (canvasRef.current && circlesRef.current.length)
        tl = canvasAppearAnimCta(canvasRef.current, circlesRef.current)
    }, 200)

    return () => {
      tl?.revert()
      clearTimeout(timeout)
    }
  }, [pathname])

  return (
    <Container as="section" className={s.cta} id="contact-us">
      <div className={s.cta_inner}>
        <div className={s.cta_ill}>
          <CircleBg
            width={871}
            height={871}
            className={s.cta_circleBg}
            variant="cta"
            circlesColor={circlesRef.current}
            ref={canvasRef}
            isColor
          />

          <p className={s.cta_text}>
            Contact us today to learn more about our services and how we can
            help you create video content that makes an impact.
          </p>
        </div>
        <Form
          className={s.cta_form}
          scaleIn={(modifier) => scaleIn(circlesRef.current, modifier)}
          scaleOut={() => scaleOut(circlesRef.current)}
        />
      </div>
    </Container>
  )
}

export default CTA
