export const TEXT_PATTERN =
  '([A-Za-zа-яА-ЯіІїЇєЄґҐ]+[ -_]?)+([ -_`][A-Za-zа-яА-ЯіІїЇєЄґҐ]+)*'

export const FORM_ERRORS = {
  required: {
    name: 'Name is required',
    email: 'Email is required',
    description: 'Please tell us about your project',
  },
  invalid: {
    name: 'Please enter a valid name',
    email: 'Please enter a valid email address',
    description: 'Please enter a valid project description',
  },
  short: {
    description: 'The description is too short',
  },
}

export const FORM_DATA = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'Your name*',
    required: true,
    pattern: TEXT_PATTERN,
  },
  {
    name: 'email',
    type: 'email',
    placeholder: 'Your email*',
    required: true,
    pattern:
      '(?![_.-])((?![_.-][_.-])[\\w.-]){0,63}[a-zA-Z\\d]@((?!-)((?!--)[a-zA-Z\\d-]){0,63}[a-zA-Z\\d]\\.){1,2}([a-zA-Z]{2,14}\\.)?[a-zA-Z]{2,14}',
  },
  {
    name: 'description',
    placeholder: 'Tell us about your project*',
    required: true,
    as: 'textarea',
    rows: 2,
    type: 'textarea',
    minLength: 10,
  },
]
