import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'

const useBelowHero = () => {
  const [isBelowHero, setIsBelowHero] = useState(false)

  useEffect(() => {
    const scrollEvent = () => {
      setIsBelowHero(window.scrollY > window.innerHeight)
    }

    window.addEventListener('scroll', throttle(scrollEvent, 200))
    return () => {
      window.removeEventListener('scroll', scrollEvent)
    }
  }, [])

  return isBelowHero
}

export default useBelowHero
