import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'

gsap.registerPlugin(CustomEase)

export const CONTACT_US_LINK = '#contact-us'
export const EASE_SMOOTH = CustomEase.create('custom', 'M0,0 C0.5,0 0.5,1 1,1 ')
export const CIRCLES_DATA_DEFAULT = [
  { radius: 0.629, color: '#ff2e00', grey: '#F9F9F9' },
  { radius: 0.525, color: '#ff4910', grey: '#F3F3F3' },
  { radius: 0.4125, color: '#ff7410', grey: '#F0F0F0' },
  { radius: 0.298, color: '#ff8d10', grey: '#EBEBEB' },
  { radius: 0.19, color: '#ffae10', grey: '#E7E7E7' },
  { radius: 0.09, color: '#ffc700', grey: '#E0E0E0' },
]
