// extracted by mini-css-extract-plugin
export var fadein = "gz_bB2";
export var fadeout = "gz_by2";
export var feedback = "gz_bD2";
export var feedback_animated = "gz_bF2";
export var feedback_btn = "gz_bL2";
export var feedback_countdown = "gz_bK2";
export var feedback_descr = "gz_bJ2";
export var feedback_error = "gz_bN2";
export var feedback_message = "gz_bG2";
export var feedback_succeed = "gz_bM2";
export var feedback_text = "gz_bH2";
export var form = "gz_bv2";
export var form_btn = "gz_bC2";
export var form_inner = "gz_bx2";
export var form_item = "gz_bw2";
export var hide = "gz_bz2";
export var show = "gz_j2";