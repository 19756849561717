import { gsap } from 'gsap'
import { EASE_SMOOTH } from '~constants'

export const canvasAppearAnimCta = (canvas, circles) => {
  return gsap
    .timeline({
      scrollTrigger: {
        trigger: canvas,
        start: 'center bottom',
      },
    })
    .from(circles, {
      pixi: { scale: 1.5 },
      ease: EASE_SMOOTH,
      duration: 1,
    })
}

export const scaleIn = (circles, multiplier) => {
  gsap.to(circles, {
    pixi: { scale: multiplier },
    duration: 0.8,
    stagger: { each: 0.1, from: 'end' },
  })
}

export const scaleOut = (circles) => {
  gsap.to(circles, {
    pixi: { scale: 1 },
    duration: 0.8,
    stagger: { each: 0.1, from: 'end' },
  })
}
